import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import { memo, useEffect, useState } from "react";
import Modal from "../components/Modal";
import apis from "../services";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import DeleteModal from "../components/DeleteModal";

const Users = () => {

  const [show, setShow] = useState(false);

  const [id, setId] = useState('');

  const handleClose = () => {
    setShow(false)
    setId('');
  }
  const [values, setValues] = useState([]);

  const { data, isLoading, refetch } = useQuery(
    ["getAllUsers"],
    () => apis.getAllUsers(),
    {
      onError: function ({ message }) {
        console.log(message);
      },
      onSuccess: (data) => {
        let userData = data?.data?.user;
        setValues(userData);
      },
    }
  );

  const { mutate: mutateDelete, isLoading: isLoadingDelete } = useMutation(apis.deleteUser, {
    onError: function ({ message }) {
      console.log(message);
    },
    onSuccess: ({ data }) => {
      if (data.status) {
        refetch()
        console.log(data)
        toast.success(data.message, { id: 1 })
        handleClose();
      }
    },
  });

  const handleShow = (id) => {
    setShow(true);
    setId(id)
  }

  const handleDelete = async (id) => {
    mutateDelete(id)
    setId('')
  }

  if (isLoading || isLoadingDelete) return <div
    style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <Spinner animation="border" variant="primary" />
  </div>


  const renderUsersList = values?.length > 0 && values.map((user, index) => {
    const { name, email, country, _id } = user;
    return (
      <tbody key={index}>
        <tr>
          <td>{index + 1}</td>
          <td>
            {name}
          </td>
          <td>{email}</td>
          <td>{country}</td>

          <td>

            <Button variant="outline-danger" className='mx-2 my-2 button-size action-btn' onClick={() => handleShow(_id)}>Delete</Button>
          </td>
        </tr>
      </tbody>
    )
  }
  );


  return <>
    <Container fluid className="main-height">
      <Row>
        <Col lg={12} md={12} className="p-0">
          <div className="custom-chart-margin">
            <h5 className="section-title">
              Users
            </h5>
            <div className="overflow-auto user-table">
              <table className="table table-striped mt-3">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Country</th>
                    <th scope="col">Action</th>

                  </tr>
                </thead>
                {renderUsersList}
              </table>
            </div>
          </div>
        </Col>
      </Row>

      <DeleteModal
        show={show}
        handleClose={handleClose}
        handleSubmit={handleDelete}
        loader={isLoadingDelete}
        id={id}
        title={"Delete User"}
      />
    </Container>

  </>
}
export default memo(Users);