import React, { useState, useEffect } from 'react'
import { Button, Col,Table, Container, Form, Modal as CustomModal, Row } from "react-bootstrap";
import apis from '../services';
import { useQuery } from '@tanstack/react-query';

const Modal = ({ show, handleClose, modalTitle, handleSubmit, data, setData ,loader,reqireState ,id,recipeStepId}) => {


// const { data:reciepeData, isLoading , refetch } = useQuery(
//     ["getOneRecipeStep"],
//     () => apis.getOneRecipeStep(recipeStepId,{
//         enabled:false
//     }),
//     {
//       onError: function ({ message }) {
//         console.log(message);
//       },
//       onSuccess: (reciepeData) => {
//         let recipeData = reciepeData?.data?.recipeStep;

//         setData(recipeData);
//       },
//     }
//   );

//   useEffect(() => {
//     if(recipeStepId){
//         refetch();
//     }
// },[recipeStepId]);

    useEffect(() => {
        if(modalTitle === 'Create Recipe') {
            setData({ image: '', description: '',no:'', duration: '', recipeId: id })
        }
    },[modalTitle]);

    const onChangeHandler = (e) => {
        const { name, value, files } = e.target;
        if (files) {
            setData(prevData => ({ ...prevData, [name]: files[0] }))
        } else {
            setData(prevData => ({ ...prevData, [name]: value }))
        }
    }


    return (
        <CustomModal show={show} onHide={handleClose} centered className='custom-modal'>
            <CustomModal.Header>
                <CustomModal.Title className ='text-color'>{modalTitle} Step</CustomModal.Title>
            </CustomModal.Header>
            <Form onSubmit={(e)=>{
                e.preventDefault() 
                handleSubmit(data)}}>
            <CustomModal.Body>

                <div className="dashboard-box  reward-token-box ">

                    
                        <Form.Group className="mb-3" controlId="formFileSm">
                            <Form.Label className ='text-color'>Image</Form.Label>
                            <Form.Control type="file" accept="image/png, image/gif, image/jpeg"size="sm" className='custom-input' name="image"
                                onChange={onChangeHandler} 
                                required ={reqireState} />
                        </Form.Group>
                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                        >
                            <Form.Label className ='text-color'>Recipe Step Number</Form.Label>
                            <Form.Control type="number" name='no' className='custom-input' placeholder="Step No" value={data?.no}
                                onChange={onChangeHandler} required />
                        </Form.Group>

                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                        >
                            <Form.Label className ='text-color'>Recipe Step Title</Form.Label>
                            <Form.Control type="text" name='title' className='custom-input' placeholder="Step Title" value={data?.title}
                                onChange={onChangeHandler} required />
                        </Form.Group>

                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                        >
                            <Form.Label className ='text-color'>Recipe Step Description</Form.Label>
                            <Form.Control type="text" name='description' className='custom-input' placeholder="Description" value={data?.description}
                                onChange={onChangeHandler} required />
                        </Form.Group>

                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                        >
                            <Form.Label className ='text-color'>Recipe Step Duration</Form.Label>
                            <Form.Control type="text" className='custom-input' name='duration' placeholder="Step Duration"
                                value={data?.duration} onChange={onChangeHandler}
                                required />
                        </Form.Group>
                </div>
            </CustomModal.Body>
            <CustomModal.Footer>
                {/* <button className='custom-btn secondary-btn round-btn' style={{backgroundColor:'white' ,color:'black', border:'none'}} onClick={handleClose}>
                    Close
                </button> */}

                <button className='custom-btn primary-btn round-btn' type='submit' disabled={loader}>
                {loader? "Loading" : `${modalTitle} Step`} 
                </button>
            </CustomModal.Footer>
            </Form>
        </CustomModal>

    )
}

export default Modal;