import React from 'react'

function Coffee() {
    return (
        <div className='svg-coffee'>
            <svg width="51" height="55" viewBox="0 0 51 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M39.5168 22.6107C40.7343 22.6107 41.941 22.5359 43.1371 22.6107C44.5024 22.6937 45.8227 23.1302 46.9684 23.8772C48.1142 24.6242 49.0461 25.6562 49.6728 26.872C50.2996 28.0878 50.5996 29.4455 50.5434 30.8122C50.4872 32.1788 50.0769 33.5074 49.3525 34.6676C48.2165 36.6393 46.6214 38.3077 44.7027 39.5311C42.784 40.7544 40.5983 41.4966 38.3314 41.6946C37.7919 41.6878 37.2595 41.8174 36.7834 42.0713C36.3073 42.3252 35.9031 42.6952 35.6082 43.147C35.186 43.8373 34.5764 44.3935 33.8504 44.7508C33.1244 45.1082 32.3119 45.2519 31.5073 45.1654C24.6298 45.0373 17.7523 45.1654 10.8855 45.0907C10.236 45.0616 9.6137 44.8217 9.1128 44.4072C7.72061 43.172 6.59097 41.6695 5.79109 39.989C4.9912 38.3085 4.53738 36.4843 4.45659 34.6249C4.12553 30.0862 4.22166 25.5261 4.16826 20.9661C4.15476 20.7595 4.18458 20.5525 4.25583 20.3582C4.32709 20.1639 4.4382 19.9866 4.582 19.8378C4.72581 19.689 4.89913 19.5718 5.09087 19.4939C5.28262 19.4161 5.48854 19.3791 5.6954 19.3855C6.08668 19.364 6.47885 19.364 6.87013 19.3855H36.5693C39.111 19.3855 39.3139 19.5778 39.4314 22.0767C39.4314 22.1728 39.4741 22.301 39.5168 22.6107ZM39.4207 25.8145C39.4207 30.0862 39.6343 34.2939 38.246 38.4695C39.9638 38.2672 41.6115 37.6703 43.0602 36.7255C44.509 35.7806 45.7196 34.5135 46.5972 33.023C47.0314 32.348 47.2847 31.5726 47.3325 30.7714C47.3804 29.9702 47.2213 29.1702 46.8705 28.4483C46.5198 27.7263 45.9891 27.1068 45.3297 26.6493C44.6702 26.1918 43.9041 25.9116 43.1051 25.8358C41.9517 25.729 40.7663 25.8252 39.4207 25.8252V25.8145Z" fill="#3E3E3E" />
                <path d="M21.7784 48.3051H40.3498C42.2507 48.3051 42.8701 49.0847 42.4857 50.9216C42.2546 52.0118 41.6501 52.9872 40.7767 53.6793C39.9032 54.3715 38.8155 54.737 37.7013 54.7127C34.7111 54.7127 31.7209 54.7127 28.7413 54.7127H6.10117C4.95472 54.7861 3.81933 54.4509 2.89655 53.7667C1.97378 53.0824 1.32328 52.0933 1.06052 50.975C0.62267 49.1915 1.30614 48.3051 3.12163 48.3051H21.7784Z" fill="#3E3E3E" />
                <path d="M26.5628 11.5576C26.5495 10.7082 26.7358 9.8675 27.1068 9.10328C27.4778 8.33906 28.023 7.67265 28.6987 7.15769C30.4074 5.76938 30.4714 4.41311 28.9229 2.88596C28.7577 2.75075 28.6209 2.58405 28.5206 2.39555C28.4202 2.20706 28.3583 2.00052 28.3384 1.78792C28.3186 1.57531 28.3411 1.36086 28.4048 1.15704C28.4685 0.953218 28.572 0.764081 28.7093 0.600586C28.8474 0.437392 29.0169 0.303676 29.2078 0.207444C29.3987 0.111211 29.607 0.0544359 29.8203 0.0404815C30.0336 0.0265271 30.2475 0.0556829 30.4493 0.126228C30.6511 0.196773 30.8366 0.30726 30.9947 0.451076C31.6313 0.935665 32.1575 1.55018 32.5383 2.2538C32.9192 2.95741 33.1459 3.73402 33.2035 4.53201C33.2611 5.33 33.1482 6.13109 32.8723 6.88209C32.5964 7.63309 32.1639 8.31678 31.6034 8.88774C31.3529 9.13674 31.0891 9.37203 30.8132 9.59258C30.5118 9.78822 30.2624 10.054 30.0864 10.3672C29.9103 10.6804 29.8129 11.0316 29.8024 11.3908C29.7919 11.7499 29.8686 12.1062 30.0261 12.4291C30.1835 12.7521 30.417 13.032 30.7064 13.2449C31.6461 14.1313 31.7743 14.9856 31.0908 15.7439C30.9318 15.914 30.7387 16.0488 30.5241 16.1392C30.3095 16.2297 30.0783 16.2738 29.8454 16.2688C29.6126 16.2637 29.3835 16.2096 29.173 16.11C28.9625 16.0103 28.7754 15.8674 28.6239 15.6905C27.9865 15.2057 27.469 14.5809 27.1116 13.8642C26.7542 13.1476 26.5665 12.3584 26.5628 11.5576Z" fill="#3E3E3E" />
                <path d="M17.165 4.8723C17.1489 5.72738 16.9501 6.56911 16.5816 7.34093C16.2132 8.11274 15.6839 8.79675 15.0291 9.34694C13.3097 10.778 13.2563 11.8672 14.8155 13.4051C14.9841 13.5378 15.124 13.7031 15.227 13.8913C15.33 14.0794 15.3939 14.2865 15.4149 14.5C15.4358 14.7134 15.4134 14.9289 15.3489 15.1335C15.2845 15.3381 15.1793 15.5275 15.0398 15.6904C14.9016 15.8531 14.7324 15.9865 14.5419 16.0829C14.3515 16.1792 14.1437 16.2366 13.9308 16.2515C13.7179 16.2664 13.5042 16.2386 13.3021 16.1698C13.1001 16.101 12.9139 15.9925 12.7544 15.8506C12.1028 15.3709 11.5658 14.7524 11.1821 14.04C10.7985 13.3275 10.5777 12.5387 10.5358 11.7306C10.4939 10.9225 10.632 10.1152 10.9399 9.36683C11.2478 8.61851 11.718 7.94788 12.3166 7.40329C12.4767 7.25378 12.6583 7.13631 12.8185 7.00816C13.149 6.79243 13.423 6.50052 13.6174 6.15697C13.8117 5.81342 13.9208 5.42825 13.9354 5.0338C13.9501 4.63935 13.8699 4.24715 13.7015 3.89013C13.5332 3.5331 13.2816 3.2217 12.968 2.98205C12.7765 2.85272 12.6145 2.68423 12.4929 2.48771C12.3713 2.2912 12.2928 2.07112 12.2625 1.84201C12.2322 1.6129 12.2509 1.37998 12.3173 1.15862C12.3837 0.937253 12.4963 0.732481 12.6476 0.557838C12.7996 0.393821 12.9838 0.262967 13.1887 0.173479C13.3936 0.0839908 13.6148 0.0378036 13.8384 0.0378036C14.0619 0.0378036 14.2831 0.0839908 14.488 0.173479C14.6929 0.262967 14.8771 0.393821 15.0291 0.557838C15.6872 1.06957 16.2211 1.72343 16.591 2.47054C16.9608 3.21765 17.157 4.03869 17.165 4.8723Z" fill="#3E3E3E" />
                <path d="M25.1531 5.06451C25.1298 5.91058 24.9195 6.741 24.5373 7.49617C24.1551 8.25135 23.6105 8.91255 22.9425 9.43236C21.3833 10.6925 21.3406 11.9634 22.7716 13.3517C22.9512 13.4825 23.1018 13.6489 23.2139 13.8407C23.3261 14.0324 23.3974 14.2452 23.4235 14.4658C23.4496 14.6863 23.4298 14.9099 23.3654 15.1225C23.301 15.3351 23.1934 15.5321 23.0493 15.7011C22.9017 15.8686 22.7213 16.0041 22.5193 16.0992C22.3173 16.1943 22.0979 16.2469 21.8747 16.2539C21.6516 16.2609 21.4293 16.2221 21.2218 16.1399C21.0142 16.0577 20.8256 15.9338 20.6678 15.7759C20.0525 15.304 19.5453 14.706 19.1802 14.022C18.8151 13.3379 18.6005 12.5837 18.551 11.8099C18.5014 11.0361 18.618 10.2606 18.8928 9.53557C19.1677 8.81053 19.5945 8.15269 20.1445 7.6062C20.4115 7.33922 20.7319 7.10427 21.0202 6.83729C21.3035 6.60802 21.5319 6.31836 21.6888 5.98947C21.8458 5.66058 21.9272 5.30077 21.9272 4.93637C21.9272 4.57196 21.8458 4.21215 21.6888 3.88326C21.5319 3.55437 21.3035 3.26471 21.0202 3.03545C20.0911 2.15974 19.9523 1.29472 20.6678 0.536487C21.3833 -0.221745 22.2056 -0.157678 23.1347 0.611234C23.7962 1.14375 24.3235 1.82413 24.6741 2.59761C25.0246 3.37108 25.1887 4.21605 25.1531 5.06451Z" fill="#3E3E3E" />
            </svg>
        </div>
    )
}

export default Coffee