import React from 'react'

function ThreeDots() {
    return (
        <div className='svg-three-dots' style={{width: '100%' , height : '50px' , display: 'flex' , justifyContent : 'center' , alignItems : 'center' , cursor : 'pointer'}}>
            <svg width="26" height="6" viewBox="0 0 26 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="3" cy="3" r="3" fill="white" />
                <circle cx="13" cy="3" r="3" fill="white" />
                <circle cx="23" cy="3" r="3" fill="white" />
            </svg>
        </div>
    )
}

export default ThreeDots