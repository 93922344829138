import {Button, Col, Container, Row, Spinner} from "react-bootstrap";
import {memo, useEffect, useState} from "react";
import Modal from "../components/Modal";
import apis from "../services";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import { Grid, IconButton, Tooltip } from "@mui/material";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {
  Delete,
  RemoveModerator,
  Send,
  VerifiedUser,
  Visibility,
  MonetizationOn 
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

import { useParams } from "react-router-dom";
import DeleteModal from "../components/DeleteModal";
const CoffeRecipeSteps = () => {
  const [show, setShow] = useState(false);
  const [Recipes, setRecipes] = useState([]);
  const [title, setTitle] = useState('');
  const [loader, setLoader] = useState(false);
  const [reqireState, SetreqireState] = useState(true);
  const [isFetching, setFetching] = useState(false);

  const {id} = useParams();

  const [showDelete, setShowDelete] = useState(false);

  const handleShowDelete = (id) => {
    setShowDelete(true);
    setRecipeStepId(id)
  }

  const handleCloseDelete = () => 
  {
    setShowDelete(false);
    setRecipeStepId('')
  }


  const [modalData, setModalData] = useState({ image: '', description: '',no:'',title:'', duration: '', recipeId: id})


  const [values, setValues] = useState([]);
  
  const [recipeStepId, setRecipeStepId] = useState('');
  

  const { mutate: mutateDelete, isLoading: isLoadingDelete } = useMutation(apis.deleteRecipeStep, {
    onError: function ({ message }) {
      console.log(message);
    },
    onSuccess: ({ data }) => {
      if (data.status) {
        refetch()
        console.log(data)
        toast.success(data.message,{id:1})
       handleCloseDelete();
      }
    },
  });

  const { mutate: mutateEdit, isLoading: isLoadingEdit} = useMutation(apis.updateRecipeStep, {
    onError: function ({ message }) {
      console.log(message);
    },
    onSuccess: ({ data }) => {
      if (data.status) {
        refetch()
        toast.success(data.message,{id:1})
        setShow(false)
        SetreqireState(true)
      }
    },
  });
  const { mutate: mutateAddRecipeStep, isLoading: isLoadingAdd} = useMutation(apis.createRecipeStep, {
    onError: function ({ message }) {
      console.log(message);
    },
    onSuccess: ({ data }) => {
      if (data.status) {
        refetch()
        toast.success(data.message,{id:1})
        setShow(false)
        SetreqireState(true)
      }
    },
  });

  console.log(values,"VALUES")
  const handleShow = (Recipe, title,recipeStepId,index) => {
    if (title === 'Edit Recipe') {
      SetreqireState(false)
      setRecipeStepId(recipeStepId)
      const {__v,createdAt,updatedAt,_id,...rest}=Recipe[index];
      setModalData(rest);
       
    }
  
    setTitle(title)
    setShow(true);
  }

  const handleClose = () => 
  {
    setShow(false)
    setRecipeStepId('');  
  }
  const handleSubmit = async (Recipes) => {
      if (title === "Edit Recipe") {
        const form_data = new FormData();

        for (const [key, value] of Object.entries(Recipes)) {
          form_data.append(key, value);
        }
        console.log(recipeStepId,form_data,"up")
        mutateEdit({recipeStepId,form_data})
        
      }
       else {
        console.log("ggg")
        const form_data = new FormData();
        for (const [key, value] of Object.entries(Recipes)) {
          if(key!=="_id")
          {
            form_data.append(key, value);
          }
        }
        mutateAddRecipeStep(form_data)
      }
  }

  const handleDelete = async (id) => {
    mutateDelete(id)
  }

 
  
  
  
  
  
  
  
  
  
  
  const navigate= useNavigate();  

  const [recipe, setRecipe] = useState({});
 
  const {id : _id} = useParams();
  const { data, isLoading , refetch } = useQuery(
    ["getRecipeSteps"],
    () => apis.getRecipeStep(id),
    {
      onError: function ({ message }) {
        console.log(message);
      },
      onSuccess: (data) => {
        let recipeData = data?.data?.recipeStep;
        setRecipe(recipeData);
      },
    }
  );



  if (isLoading) return <div
    style={{height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
    <Spinner animation="border" variant="primary"/>
  </div>


  const renderRecipesList = recipe?.length > 0 && recipe.map((item, index) => {
      return (
        <tbody key={index}>
        <tr>
          <td scope="row">{index + 1}</td>
          <td>
          <img style={{width:50}} src={item?.image} alt=""/></td>
          <td>{item?.no}</td>
          <td className='line-clamp'>{item?.title}</td>    
          <td className='line-clamp'>{item?.description}</td>    
          <td>{item?.duration} sec</td>
          

          <td>


             <Grid container>
            <Grid item>
              <Tooltip title="View And Edit" arrow>
                <IconButton
                  size="small"
                  onClick={() => handleShow(recipe, 'Edit Recipe',item?._id,index)}
                >
                  <Visibility />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title="Delete" arrow>
                <IconButton onClick={() => handleShowDelete(item?._id)} size="small">
                  <Delete />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        
          </td>
        </tr>
        </tbody>
      )
    }
  );


  return <>
    <Container fluid className="main-height">
      <Row>
        <Col lg={12} md={12} className="p-0">
          <div className="custom-chart-margin">
            <div className="d-flex justify-content-between">

            <h5 className="section-title">
           Coffe Recipe Steps
            </h5>
            <button className="lp-btn"  onClick={() => handleShow(null, 'Create Recipe')} style={{width:"220px"}}>
           Add Recipe Steps
            </button>
            </div>
            
            
            <div className="overflow-auto user-table coffe-recipe-steps">
              <table className="table table-striped mt-3">
                <thead>
                <tr>

                  <th scope="col">#</th>
                  <th scope="col">Image</th>
                  <th scope="col">Step No</th>
                  <th scope="col">Title</th>
                  <th scope="col">Description</th>
                  <th scope="col">Duration</th>
                  
                  <th width="150px" scope="col">Action</th>
                </tr>
                </thead>
                {renderRecipesList}
              </table>
            </div>
          </div>
        </Col>
      </Row>
      <Modal modalTitle={title}
             show={show}
             data={modalData}
             setData={setModalData}
             handleClose={handleClose}
             handleSubmit={handleSubmit}
             loader= {isLoadingAdd || isLoadingEdit}
             reqireState={reqireState}
             id={_id}
             recipeStepId={recipeStepId}
      />

<DeleteModal 
             show={showDelete}
             handleClose={handleCloseDelete}
             handleSubmit={handleDelete}
             loader={isLoadingDelete}
             id={recipeStepId}
             title={"Delete Recipe Step"}
      />
    </Container>


  </>
}
export default memo(CoffeRecipeSteps);