import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import { memo, useEffect, useState } from "react";
import Modal from "../components/Modal";
import apis from "../services";
import { useMutation, useQuery } from "@tanstack/react-query";

import { Grid, IconButton, Tooltip } from "@mui/material";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CoffeeMakerIcon from '@mui/icons-material/CoffeeMaker';
import {
  Delete,
  Visibility,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import DeleteModal from "../components/DeleteModal";
import ThreeDots from "../components/shared/svgs/ThreeDots";

const CoffeRecipe = () => {

  const navigate = useNavigate();
  const [recipe, setRecipe] = useState([]);

  const [show, setShow] = useState(false);
  const [activeIndex, setActiveIndex] = useState('');
  const [id, setId] = useState('');

  const handleShow = (id) => {
    setShow(true);
    setId(id)
  }

  const handleClose = () => {
    setShow(false);
    setId('')
  }



  const { data, isLoading, refetch } = useQuery(
    ["getAllRecipe"],
    () => apis.getAllRecipe(),
    {
      onError: function ({ message }) {
        console.log(message);
      },
      onSuccess: (data) => {
        let recipeData = data?.data?.recipe;
        setRecipe(recipeData);
      },
    }
  );

  const { mutate: mutateDelete, isLoading: isLoadingDelete } = useMutation(apis.deleteRecipe, {
    onError: function ({ message }) {
      console.log(message);
    },
    onSuccess: ({ data }) => {
      if (data.status) {
        refetch()
        console.log(data)
        toast.success(data.message, { id: 1 })
        handleClose();
      }
    },
  });

  const handleDelete = async (id) => {
    mutateDelete(id)
  }

  if (isLoading) return <div
    style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <Spinner animation="border" variant="primary" />
  </div>


  const renderBlogsList = recipe?.length > 0 && recipe.map((item, index) => {
    return (
      <tbody key={index}>
        <tr>
          <td scope="row">{index + 1}</td>
          <td><img style={{ width: '50px' }} src={item?.image} alt="" /></td>
          <td>{item?.name}</td>
          <td className='line-clamp'>{item?.description}</td>
          <td>{item?.ratio}</td>

          <td>{item?.grind_size}</td>
          <td >{item?.gram.water}</td>
          <td>{item?.gram.coffe}</td>

          <td >{item?.oZ.water}</td>
          <td >{item?.oZ.coffe}</td>

          <td>
            <div style={{position : 'relative'}} onClick={() => setActiveIndex(item?._id)}>
              <ThreeDots/>
              {item?._id === activeIndex 
              &&
              <div className="option-lists">
                <ul>
                  <li onClick={() => navigate(`/edit-recipe/${item?._id}`)}>Edit Recipe</li>
                  <li onClick={() => handleShow(item?._id)} size="small">Delete Recipe</li>
                  <li onClick={() => navigate(`/add-recipe-steps/${item?._id}`)}>Add Recipe Steps</li>
                  <li onClick={() => navigate(`/coke-steps/${item?._id}`)}>Add Cook Steps</li>
                </ul>
              </div>
              }

            </div>


            {/* <Grid container>
              <Grid item>
                <Tooltip title="View And Edit Recipe" arrow>
                  <IconButton
                    size="small"
                    onClick={() => navigate(`/edit-recipe/${item?._id}`)}
                  >
                    <Visibility />
                  </IconButton>
                </Tooltip>
              </Grid>

              <Grid item>
                <Tooltip title="Delete" arrow>
                  <IconButton onClick={() => handleShow(item?._id)} size="small">
                    <Delete />
                  </IconButton>
                </Tooltip>
              </Grid>

              <Grid item>
                <Tooltip title="View And Add Recipe Steps" arrow>
                  <IconButton
                    size="small"
                    onClick={() => navigate(`/add-recipe-steps/${item?._id}`)}
                  >
                    <AddCircleIcon />
                  </IconButton>
                </Tooltip>
              </Grid>

              <Grid item>
                <Tooltip title="View And Add How to Coke Steps" arrow>
                  <IconButton
                    size="small"
                    onClick={() => navigate(`/coke-steps/${item?._id}`)}
                  >
                    <CoffeeMakerIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid> */}

          </td>
        </tr>
      </tbody>
    )
  }
  );


  return <>
    <Container fluid className="main-height">
      <Row>
        <Col lg={12} md={12} className="p-0">
          <div className="custom-chart-margin">

            <div className="d-flex justify-content-between align-center">
              <h5 className="section-title">
                Coffe Recipe</h5>
              <button className="lp-btn" onClick={() => navigate("/add-recipe")} style={{ width: "150px" }}>
                Add Recipe
              </button>
            </div>

            <div className="overflow-auto user-table offe-cecipe">
              <table className="table table-striped mt-3">
                <thead>
                  <tr>

                    <th scope="col">#</th>
                    <th scope="col">Image</th>
                    <th scope="col">Name</th>
                    <th scope="col">Description</th>
                    <th scope="col">Ratio</th>

                    <th scope="col">Grind Size</th>
                    <th scope="col">Gram Water</th>
                    <th scope="col">Gram Coffe</th>

                    <th scope="col">Oz Water</th>
                    <th scope="col">Oz Coffe</th>
                    <th width="150px" scope="col">Action</th>
                  </tr>
                </thead>
                {renderBlogsList}
              </table>
            </div>
          </div>
        </Col>
      </Row>
      <DeleteModal
        show={show}
        handleClose={handleClose}
        handleSubmit={handleDelete}
        loader={isLoadingDelete}
        id={id}
        title={"Delete Recipe"}
      />
    </Container>


  </>
}
export default memo(CoffeRecipe);