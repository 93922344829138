import React from 'react'

function Dashboard() {
    return (
        <div className='svg-dashboard'>
            <svg width="44" height="45" viewBox="0 0 44 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M23.5785 13.5378V4.49439C23.5785 2.16144 24.8171 0.922847 27.1246 0.922847H40.011C40.4794 0.880715 40.9513 0.942418 41.3932 1.10354C41.835 1.26467 42.2359 1.52125 42.5672 1.85502C42.8986 2.1888 43.1522 2.59154 43.3101 3.03455C43.468 3.47756 43.5263 3.94995 43.4807 4.41805C43.4807 10.5488 43.4807 16.6795 43.4807 22.8102C43.5245 23.2686 43.4671 23.7311 43.3127 24.1649C43.1582 24.5988 42.9105 24.9935 42.5869 25.3211C42.2633 25.6488 41.8717 25.9014 41.4398 26.0612C41.0079 26.221 40.5462 26.2841 40.0873 26.246C35.7353 26.246 31.3833 26.246 27.0312 26.246C26.5713 26.2887 26.1075 26.2288 25.6735 26.0707C25.2395 25.9125 24.846 25.6599 24.5214 25.3313C24.1968 25.0026 23.9491 24.606 23.7963 24.17C23.6436 23.734 23.5894 23.2696 23.6379 22.8102C23.57 19.6968 23.5785 16.6173 23.5785 13.5378Z" fill="#3E3E3E" />
                <path d="M19.9389 31.7094V40.7782C19.9389 43.1027 18.7003 44.3243 16.3674 44.3243H3.5658C3.09147 44.3747 2.61187 44.3186 2.16195 44.1602C1.71203 44.0017 1.30314 43.7449 0.96504 43.4085C0.62694 43.072 0.368154 42.6643 0.207552 42.2152C0.0469508 41.766 -0.0114281 41.2867 0.0366685 40.8121V22.4963C0.0366685 20.2821 1.29223 19.0096 3.48945 19.0011H16.4607C18.7003 19.0011 19.9304 20.2312 19.9304 22.4624C19.9474 25.5419 19.9389 28.6214 19.9389 31.7094Z" fill="#3E3E3E" />
                <path d="M9.92846 15.3532C7.75669 15.3532 5.57643 15.3532 3.40466 15.3532C2.94927 15.3919 2.49092 15.3307 2.06164 15.1739C1.63237 15.017 1.24253 14.7683 0.91936 14.4451C0.59619 14.122 0.347485 13.7321 0.190635 13.3028C0.0337861 12.8736 -0.027425 12.4152 0.0112729 11.9598C0.0112729 9.4148 0.0112729 6.81886 0.0112729 4.24836C-0.022165 3.79854 0.0409556 3.34676 0.196396 2.92333C0.351836 2.49989 0.596007 2.11459 0.912538 1.79324C1.22907 1.47188 1.61064 1.22192 2.03168 1.0601C2.45271 0.898276 2.90349 0.828339 3.35376 0.854975H16.5795C17.0198 0.825403 17.4614 0.889776 17.875 1.04384C18.2886 1.1979 18.6646 1.43813 18.9783 1.74859C19.292 2.05905 19.536 2.43264 19.6944 2.8446C19.8527 3.25655 19.9216 3.69746 19.8965 4.13807C19.8965 6.79057 19.8965 9.4459 19.8965 12.1041C19.9171 12.5299 19.8484 12.9553 19.6951 13.3531C19.5417 13.7509 19.307 14.1123 19.006 14.4141C18.7049 14.716 18.3441 14.9516 17.9468 15.106C17.5494 15.2605 17.1241 15.3302 16.6983 15.3108C14.4671 15.3787 12.2105 15.3532 9.92846 15.3532Z" fill="#3E3E3E" />
                <path d="M33.538 29.86H40.0702C40.5307 29.8215 40.9941 29.8849 41.4273 30.0457C41.8605 30.2065 42.253 30.4607 42.5769 30.7903C42.9009 31.1199 43.1482 31.5167 43.3015 31.9527C43.4547 32.3886 43.5101 32.853 43.4636 33.3127C43.4636 35.8578 43.4636 38.4028 43.4636 40.9479C43.4979 41.4023 43.4336 41.8587 43.2752 42.2859C43.1168 42.7132 42.868 43.1012 42.5458 43.4234C42.2236 43.7456 41.8356 43.9944 41.4083 44.1529C40.9811 44.3113 40.5246 44.3755 40.0702 44.3413H26.9293C26.479 44.3803 26.0255 44.3213 25.6001 44.1684C25.1747 44.0155 24.7875 43.7723 24.465 43.4555C24.1426 43.1387 23.8926 42.7558 23.7322 42.3332C23.5718 41.9106 23.5049 41.4582 23.536 41.0073C23.536 38.4113 23.536 35.8069 23.536 33.2109C23.4985 32.7607 23.5598 32.3077 23.7155 31.8835C23.8712 31.4594 24.1176 31.0744 24.4375 30.7553C24.7574 30.4362 25.143 30.1908 25.5676 30.0361C25.9921 29.8815 26.4452 29.8214 26.8954 29.86C29.1351 29.843 31.3408 29.86 33.538 29.86Z" fill="#3E3E3E" />
            </svg>

        </div>
    )
}

export default Dashboard