import React from "react";
import {Navigate, Outlet, useLocation} from "react-router-dom";
import {useSelector} from "react-redux";

const ProtectedRoute = ({isAdmin = false}) => {
    const {pathname} = useLocation()
    const {admin} = useSelector((store) => store.user);

    return admin ? <Outlet/> : <Navigate to={'/sign-in'} state={{from: pathname}}/>
}

export default ProtectedRoute
