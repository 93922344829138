import React, { Fragment, useEffect, useState, useRef } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
const AddRecipeForm = ({
  formData,
  submitHandler,
  onChangeHandler,
  heading,
  recipeId,
}) => {
  const reqireState = recipeId ? false : true;

  const GRIND_SIZE = ["Coarse", "Fine", "Medium"];

  const [chips, setChips] = useState([]);
  const fileInputRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
    console.log(file);
    console.log(selectedImage?.name);
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  const handleAddChip = (chip) => {
    setChips([...chips, chip]);
  };

  const handleDeleteChip = (chip, index) => {
    setChips(chips.filter((c, i) => i !== index));
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [selectedImage]);

  const submit = (e) => {
    e.preventDefault();
    submitHandler(formData);
    e.currentTarget.reset();
  };

  return (
    <Fragment>
      <div className="multiform">
        <Form onSubmit={submit} className="my-4">
          <Row className="d-flex justify-content-center align-items-center">
            <Col xs={12} md={6} lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  placeholder="Recipe name"
                  required
                  onChange={onChangeHandler}
                  value={formData?.name}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6} lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  type="text"
                  name="description"
                  placeholder="Recipe Description"
                  required
                  onChange={onChangeHandler}
                  value={formData?.description}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6} lg={4}>
              <Form.Group className="mb-3" controlId="state">
                <Form.Label>Grind Size</Form.Label>
                <Form.Select
                  name="grind_size"
                  required
                  onChange={onChangeHandler}
                  value={formData?.grind_size}
                >
                  <option disabled>Select Grind Size...</option>
                  {GRIND_SIZE.map((value) => (
                    <option key={value}>{value}</option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col xs={12} md={6} lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>oZ Water</Form.Label>
                <Form.Control
                  type="number"
                  name="water"
                  placeholder="oZ Water"
                  required
                  onChange={onChangeHandler}
                  value={formData?.oZ?.water}
                />
              </Form.Group>
            </Col>

            <Col xs={12} md={6} lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>oZ Coffe</Form.Label>
                <Form.Control
                  type="number"
                  name="coffe"
                  placeholder="oZ coffe"
                  required
                  onChange={onChangeHandler}
                  value={formData?.oZ?.coffe}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6} lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>Gram Water</Form.Label>
                <Form.Control
                  type="number"
                  name="water1"
                  placeholder="Gram Water"
                  required
                  onChange={onChangeHandler}
                  value={formData?.gram?.water}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6} lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>Gram Coffe</Form.Label>
                <Form.Control
                  type="number"
                  name="coffe1"
                  placeholder="Gram Coffe"
                  required
                  value={formData?.gram?.coffe}
                  onChange={onChangeHandler}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6} lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>Recipe Image</Form.Label>
                <div className="add-recipe-upload">
                  <div className="NFT-image-holder">
                    <input ref={fileInputRef} type="file" accept="image/png, image/gif, image/jpeg" style={{ display: "none" }} onChange={handleImageUpload}/>
                  </div>
                  <div className="NFT-image-upload-txt">
                    <button onClick={handleButtonClick} className="">Choose File</button>
                    {!selectedImage 
                    ?
                  <p>No file chosen</p>
                :
                <p>{selectedImage?.name}</p>}
                  </div>
                </div>
                {/* <Form.Control
                accept="image/png, image/gif, image/jpeg"
                type="file"
                name="image"
                placeholder="Image"
                required={reqireState}
                onChange={onChangeHandler}
              /> */}
              </Form.Group>
            </Col>

            <Col xs={12} md={6} lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>Ratio</Form.Label>
                <Form.Control
                  type="text"
                  name="ratio"
                  placeholder="Ratio"
                  required
                  value={formData?.ratio}
                  onChange={onChangeHandler}
                />
              </Form.Group>
            </Col>


            <Col xs={12} md={8} lg={4}>
              <div className="update-submit-btn-parent">
              <Button className=" update-submit-btn" variant="outline-danger" type="submit">
                {" "}
                {heading} Recipe{" "}
              </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </Fragment>
  );
};

export default AddRecipeForm;
