import { Col, Container, Row } from "react-bootstrap";
import { useState, useEffect } from "react";
import { memo } from "react";
import apis from "../services";
import { useMutation, useQuery } from "@tanstack/react-query";
import Users from "../components/shared/svgs/Users";
import Book from "../components/shared/svgs/Book";
import Chart from "react-apexcharts";



function Home({ }) {

    const [values, setValues] = useState();

    const { data, isLoading, refetch } = useQuery(
        ["getAnalytics"],
        () => apis.getAnalytics(),
        {
            onError: function ({ message }) {
                console.log(message);
            },
            onSuccess: (data) => {
                let dataAnaylytics = data?.data?.data;
                setValues(dataAnaylytics);
            },
        }
    );


    const statee = {

        options: {
            chart: {
                id: "basic-bar"
            },
            xaxis: {
                categories: ['Mon', "Tue", 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
            },
            yaxis: {
                labels: {
                    show: true,
                    formatter: function (value) {
                        // Custom label formatting logic
                        // You can modify this function according to your requirements
                        if (value < 10) {
                            return '0 hour';
                        }
                        if (value <= 20 && value > 10) {
                            return '1 hour';
                        }
                        if (value <= 40 && value > 20) {
                            return '2 hour';
                        }
                        if (value <= 60 && value > 40) {
                            return '3 hour';
                        }
                        if (value <= 80 && value > 60) {
                            return '4 hour';
                        }
                        if (value <= 100 && value > 80) {
                            return '5 hour';
                        }
                        else {
                            return value + ' hours';
                        }
                    }
                }
            },
            dataLabels: {
                enabled: false
            },
            legend: {
                show: false
            },
            plotOptions: {
                bar: {
                    columnWidth: '50%',
                    distributed: true,
                    borderRadius: 5 // Adjust the value to control the roundness of corners
                }
            }
        },

        series: [
            {
                data: [99, 80, 20, 30, 90, 80, 99],
            }
        ],
        chart: {
            toolbar: {
                show: false // Hide chart toolbar
            }
        },
        grid: {
            show: false // Hide grid lines
        },
        xaxis: {
            lines: {
                show: false // Hide x-axis lines
            }
        },
        yaxis: {
            lines: {
                show: false // Hide y-axis lines
            }
        },
        tooltip: {
            enabled: false // Disable tooltips on hover
        },
        fill: {
            colors: ['#FFA500'],
            opacity: 1,
            type: 'solid'
        },
        plotOptions: {
            bar: {
                columnWidth: '50%',
                distributed: true,
                borderRadius: 5 // Adjust the value to control the roundness of corners
            }
        },
        shape: {
            type: 'roundRect' // Use roundRect shape for bars
        }
    };
    const stateeTwo = {

        options: {
            chart: {
                id: "basic-bar"
            },
            xaxis: {
                categories: ['Mon', "Tue", 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
            },
            yaxis: {
                labels: {
                    show: true,
                    formatter: function (value) {
                        // Custom label formatting logic
                        // You can modify this function according to your requirements
                        if (value < 10) {
                            return '0 hour';
                        }
                        if (value <= 20 && value > 10) {
                            return '1 hour';
                        }
                        if (value <= 40 && value > 20) {
                            return '2 hour';
                        }
                        if (value <= 60 && value > 40) {
                            return '3 hour';
                        }
                        if (value <= 80 && value > 60) {
                            return '4 hour';
                        }
                        if (value <= 100 && value > 80) {
                            return '5 hour';
                        }
                        else {
                            return value + ' hours';
                        }
                    }
                }
            },
            dataLabels: {
                enabled: false
            },
            legend: {
                show: false
            },
            plotOptions: {
                bar: {
                    columnWidth: '50%',
                    distributed: true,
                    borderRadius: 5 // Adjust the value to control the roundness of corners
                }
            }
        },

        series: [
            {
                data: [99, 80, 20, 30, 90, 80, 99],
            }
        ],
        chart: {
            toolbar: {
                show: false // Hide chart toolbar
            }
        },
        grid: {
            show: false // Hide grid lines
        },
        xaxis: {
            lines: {
                show: false // Hide x-axis lines
            }
        },
        yaxis: {
            lines: {
                show: false // Hide y-axis lines
            }
        },
        tooltip: {
            enabled: false // Disable tooltips on hover
        },
        fill: {
            colors: ['#FFA500'],
            opacity: 1,
            type: 'solid'
        },
        plotOptions: {
            bar: {
                columnWidth: '50%',
                distributed: true,
                borderRadius: 5 // Adjust the value to control the roundness of corners
            }
        },
        shape: {
            type: 'roundRect' // Use roundRect shape for bars
        }
    };


    return <>
        <Container fluid className="main-height">
            <Row>
                <Col lg={12} md={12} className="p-0">
                    <div className="custom-chart-margin">
                        <h3 className="section-title">Dashboard</h3>
                        <div className="analytics">
                            <div className="dashboard-box">
                                <div>
                                    <h3>{values?.user}</h3>
                                    <Users />
                                </div>
                                <span>Total Users</span>
                            </div>
                            <div className="dashboard-box">
                                <div>
                                    <h3>{values?.recipe}</h3>
                                    <Book />
                                </div>
                                <span>Total Recipes</span>
                            </div>

                        </div>
                        <div className="chart-one">
                            <h2>
                                Total User Time Active
                            </h2>
                            <Chart
                                options={statee.options}
                                series={statee.series}
                                type="bar"
                                width="700"
                            />
                        </div>
                        <div className="chart-two">
                            <h2>
                            Total Recipe
                            </h2>
                            <Chart
                                options={statee.options}
                                series={statee.series}
                                type="bar"
                                width="700"
                            />
                        </div>

                    </div>
                </Col>
            </Row>
        </Container>
    </>
}
export default memo(Home);