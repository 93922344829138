import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    loading: false,
    admin: null,
    error: null,
  },
  reducers: {
    setUser: (state, action) => {
      state.admin = action.payload;
    },
    logout: (state, action) => {
      state.admin = null;
    },
  },
//   extraReducers: {
//     [reduxApis.userLogin.pending]: (state) => {
//       state.loading = true;
//     },
//     [reduxApis.userLogin.fulfilled]: (state, action) => {
//       const message = action.payload.data.message;

//       state.loading = false;
//       state.user = action.payload.data.user;
//     },
//     [reduxApis.userLogin.rejected]: (state, action) => {
//       //   toast.error(action.error.message);
//       state.loading = false;
//       state.error = action.error.message;
//     },
//   },
});

export const { logout, setUser, setUserType } = userSlice.actions;

export default userSlice.reducer;
